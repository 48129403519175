// SocialMediaPost.module.scss
@import "../../scss/_mixins.scss";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.postContainer {
  width: 30%;
  margin: auto;
  background: white;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  margin: 16px 0;
  font-family: Arial, sans-serif;

  .description p {
    color: black;
  }

  .description p:not(:first-child) {
    padding-top: 1rem;
  }

  @include respond(big-desktop) {
    width: 500px;
  }

  @include respond(phone) {
    width: 95%;
    border: none;
  }
  .slick-prev,
  .slick-next {
    z-index: 1;
  }

  li {
    margin: -4px;
    color: red !important;

    button::before {
      font-size: 8px;
      color: #fff;
      color: #4495f4 !important;
    }
  }
  li.slick-active button:before {
    color: #000 !important;
  }

  margin: auto;
}
.postHeader {
  display: flex;
  align-items: center;
  padding: 16px;

  .profilePic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 16px;
    border: 1px solid #dbdbdb;
  }

  .headerInfo {
    .username {
      font-weight: bold;
      color: #000;
    }

    .timestamp {
      color: #8e8e8e;
      font-size: 0.85em;
    }
  }
}

.postContent {
  .slick-slide {
    text-align: center; // This centers the image if it's not full width

    img {
      max-width: 100%;
      margin: auto; // This centers the image horizontally if it's not full width
    }
  }
}

.postFooter {
  padding: 16px;

  .likes {
    font-weight: bold;
    margin-bottom: 8px;
    color: #000;
  }

  .icons {
    height: 25px;
    display: flex;
    column-gap: 0.8rem;
    margin-bottom: 1rem;
    margin-top: -0.51rem;
    img {
      height: 100%;
      width: auto;
    }
  }

  .description {
    color: #262626;
    line-height: 1.4;
  }
}

.showMoreButton {
  background: none;
  color: #737373;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: inherit;
  &:focus {
    outline: none;
  }
}

// .slick-slide {
//   height: auto !important;
//   display: flex !important;
//   align-items: center !important;
//   justify-content: center !important;
//   text-align: center !important;
//   position: relative !important;
// }
