@import "../../scss/_mixins.scss";

.songSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  background-color: #000;
  color: #fff;
  overflow: hidden;
  position: relative;
  min-height: 80vh;
  margin-top: 5rem;
  align-items: stretch;

  @include respond(tab-port) {
    flex-direction: column;
    margin-bottom: 5rem;
    margin-top: 5rem;
  }
  @include respond(phone) {
    flex-direction: column;
    margin-bottom: 5rem;
    margin-top: 5rem;
  }

  &Info {
    flex: 1;
    align-self: stretch;
    background-color: var(--primary-color);
    color: var(--second-color);
    display: flex;
    z-index: 99;
    padding: 5rem 0;

    @include respond(tab-port) {
      margin-right: 0;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
    @include respond(phone) {
      margin-right: 0;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    &Description {
      width: 50%;
      margin: auto;

      @include respond(tab-port) {
        width: 70%;
      }
      @include respond(phone) {
        width: 80%;
      }
    }
  }

  &Img {
    flex: 1;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: var(--primary-color);

    iframe {
      width: 400px;
      height: 400px;
    }
    @include respond(tab-port) {
      width: 100%;
      padding-bottom: 5rem;
      background-color: var(--primary-color);
      iframe {
        width: 400px;
        height: 400px;
      }
    }
    @include respond(phone) {
      width: 100%;
      padding-bottom: 5rem;
      background-color: var(--primary-color);
      iframe {
        width: 300px;
        height: 300px;
      }
    }

    &Background {
      opacity: 0.8;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120%;
      z-index: -1;
      position: absolute;
      filter: blur(100px);
      -webkit-filter: blur(100px);

      @include respond(phone) {
        display: none;
      }

      @include respond(tab-port) {
        display: none;
      }

      img {
        position: absolute;
        width: 100%;
      }
    }
  }
}
