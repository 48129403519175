/* Font-face definitions */
@font-face {
  font-family: "Nulshock";
  src: local("Nulshock"), url("./fonts/NulshockRg-Bold.woff") format("woff"),
    url("./fonts/NulshockRg-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Eurostile";
  src: local("Eurostile"), url("./fonts/EurostileRegular.woff") format("woff"),
    url("./fonts/EurostileRegular.woff2") format("woff2");
}

@font-face {
  font-family: "Raavi";
  src: local("Raavi"), url("./fonts/Raavi-Bold.woff") format("woff"),
    url("./fonts/Raavi-Bold.woff2") format("woff2");
}

/* Global styles */
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: var(--tertiary-color);
  color: var(--second-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  padding: 0;
  margin: 0;
  color: var(--second-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Nulshock, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
}

/* Anchor styles */
a {
  font-family: Nulshock, Arial, Helvetica, sans-serif;
  text-decoration: none;
  font-size: 1.3rem;
  display: block;
  cursor: pointer;
  color: var(--second-color);
}

/* Commented out since it's empty */
/* a:hover {
  color: blue;
} */

::-moz-selection {
  /* Code for Firefox */
  color: blue;
  background: blue;
}

::selection {
  color: red;
  background: yellow;
  color: #bbb;
  background: blue;
}

.slick-track {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-items: stretch !important;
}
.slick-slide {
  overflow: hidden;
}

.slick-slider {
  /* Optional: Set a minimum height if you want a baseline height for the slider */
  min-height: 300px; /* Adjust as necessary */
}

.slick-list {
  overflow: hidden; /* This will contain the images within each slide */
}

.slick-track {
  display: flex;
}

.slick-slide {
  /* Important: Don't stretch the slide items here; let the content dictate the size */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: auto !important; /* Allow slide height to grow */
  object-fit: cover !important; /* Cover the area of the slide, will be cropped if necessary */
}

.slick-slide img {
  display: block !important; /* Remove extra space below the image */

  width: auto !important; /* Scale width automatically */
  height: 400px !important; /* Use viewport height to ensure images take up as much vertical space as possible */
  max-height: 400px !important; /* Make sure image is not taller than the viewport */
  object-fit: cover !important; /* Cover the area of the slide, will be cropped if necessary */
  min-width: 100% !important; /* Make sure image is not wider than the viewport */
}

.slick-arrow {
  display: none !important;
}

.password-protection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: #fff;
  padding: 4rem;
  box-sizing: border-box;
  text-align: center;
}

.password-protection input {
  background-color: #000;
  border: none;
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 1.5rem;
  padding: 0.5rem;
  margin: 0;
  border-radius: 0;
  margin-top: 2rem;
  text-align: center;
  outline: none;
}

.password-protection p {
  margin-top: 0.5rem;
}

.password-protection button {
  background-color: #000;
  border: 3px solid #fff;
  color: white;
  padding: 0.5rem;
  font-size: 1rem;
  font-family: Helvetica;
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 2rem;
}

@media (max-width: 600px) {
  .password-protection button {
    width: 100%;
  }

  .password-protection input {
    width: 100%;
  }
}
