@import "../../scss/_mixins.scss";

.heading {
  font-family: Nulshock;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 10rem;

  @include respond(phone) {
    font-size: 1.3rem;
  }

  @include respond(tab-port) {
    font-size: 6rem;
  }
}
