.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  z-index: 8;
  justify-self: center;
  align-self: center;
  margin: auto;
  position: relative;
  cursor: pointer;
  &:hover {
    .iconTooltip {
      display: block;
    }
  }

  svg {
    height: 30px;
  }
}

.social {
  color: white;
  svg {
    height: 20px;
    width: 20px;
    margin: auto;
    justify-self: center;
  }
}

.iconTooltip {
  bottom: -200%;
  left: 0%;
  position: absolute;
  display: none;
  text-wrap: nowrap;
  text-transform: capitalize;
  background-color: #000;
  z-index: 999999999999999999 !important;
  padding: 0.5rem;
  border-radius: 5px;

  &::after {
    content: " ";
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 13%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
}
