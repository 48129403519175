@import "../../scss/_mixins.scss";

.shocker {
  margin-top: 20px;
  padding: 20px;
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top: 5rem;

  &Video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
    overflow: hidden;
    border-radius: 1rem;

    @include respond(phone) {
      border-radius: 0.5rem;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &Paragraph {
    margin: auto;
    width: 80%;

    @include respond(phone) {
      width: 100%;
    }
  }
}
