@import "../../scss/_mixins.scss";
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: top top;
  width: 100%;
  height: 50svh;
  position: relative;
  margin-top: 7rem;

  &ProfileImage {
    width: 100px;
    height: 100px;
    bottom: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 5px solid var(--tertiary-color);
    border-radius: 100%;
    overflow: hidden;

    opacity: 1;

    img {
      width: 120%;
    }
  }

  h1 {
    text-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
  }
  @include respond(phone) {
    height: 20vh;
    background-position: center center;
    margin-top: 3rem;
    h1 {
      font-size: 3rem;
      text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    }
  }

  &Container {
    width: 80%;
  }
}
