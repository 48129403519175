@import "../../scss/_mixins.scss";

.epk {
  font-family: Eurostile, sans-serif;
  a {
    font-family: Eurostile, sans-serif;
    font-weight: 900;
    text-decoration: underline;
    color: #fff;
  }
}
