@import "../../scss/_mixins.scss";

.footer {
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: 3rem;
  text-align: center;
  z-index: -1;
  flex-grow: 1;
  margin-bottom: 2rem;
  height: 100%;

  &Subtitle {
    background-color: transparent;
  }
  h1 {
    @include respond(phone) {
      font-size: 1.5rem;
    }
  }

  .smallText {
    margin-top: 2rem;
    font-family: Eurostile, sans-serif;
    display: flex;
    width: 50%;
    justify-content: center;
    margin-top: auto;
    color: var(--secondary-color);

    @include respond(phone) {
      width: 80%;
      font-size: 0.9rem;
    }
  }

  .rules {
    margin-left: 1rem;
    padding: 0;
    text-decoration: underline;
  }
}
