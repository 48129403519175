@import "../../scss/_mixins.scss";

.bio {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  min-height: 80vh;

  &Info {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @include respond(tab-port) {
      padding: 0;
      border-left: none;
      width: 80%;
      flex-direction: column;
    }
    @include respond(phone) {
      padding: 0;
      border-left: none;

      width: 80%;
      flex-direction: column;
    }
  }

  &InfoText {
    width: 70%;
    margin: auto;
    border-left: 1px solid white;
    padding-left: 2rem;

    @include respond(tab-port) {
      padding: 0;
      border-left: none;
      width: 90%;
    }
    @include respond(phone) {
      padding: 0;
      border-left: none;
      width: 100%;
    }
  }

  &Title {
    width: 100%;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  &Image {
    width: 20%;
    padding-right: 2rem;
    align-self: center;

    @include respond(phone) {
      padding: 0;
      border-right: none;
    }
  }

  @include respond(tab-port) {
    width: 100%;
    flex-direction: column;

    &Image {
      width: 50%;
      margin: auto;
      margin-bottom: 2rem;
    }
  }

  @include respond(phone) {
    width: 100%;
    flex-direction: column;

    &Image {
      width: 50%;
      margin: auto;
      margin-bottom: 2rem;
    }
  }
}
