@import "../../scss/_mixins.scss";

.navbar {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 999999;

  a {
    color: var(--second-color) !important;
  }

  @include respond(phone) {
    justify-content: center;
  }

  align-items: center;
  list-style: none;
  background-color: var(--tertiary-color);
  flex-grow: 1;
  top: 0;
  a {
    padding: 2.5rem;
    font-size: 1.3rem;

    @include respond(tab-port) {
      padding: 0.5rem;
      font-size: 1.3rem;
    }

    @include respond(phone) {
      padding: 0.5rem;
      font-size: 2rem;
      color: #000;
    }
  }

  &.defaultColors {
    background-color: black;

    a {
      color: white !important;
    }
  }
}

.burger {
  display: none;

  width: 1.5rem;
  position: absolute;
  top: 50px;
  left: 14px;
  box-sizing: border-box;
  z-index: 999999999;
  @include respond(phone) {
    display: grid;
    row-gap: 0.4rem;
    top: 14px;
  }
  .burgerMenuLine1 {
    width: 100%;
    height: 2px;
    background-color: var(--second-color);
    transition: all 200ms;
  }

  .burgerMenuLine2 {
    width: 100%;
    height: 2px;
    background-color: var(--second-color);
    transition: all 200ms;
  }

  .burgerMenuLine3 {
    width: 100%;
    height: 2px;
    background-color: var(--second-color);
    transition: all 200ms;
  }

  &.expanded {
    .burgerMenuLine1 {
      transform: rotate(45deg) translate(6px, 6px);
      background-color: var(--tertiary-color);
    }

    .burgerMenuLine2 {
      opacity: 0;
      background-color: var(--tertiary-color);
    }

    .burgerMenuLine3 {
      transform: rotate(-45deg) translate(6px, -5px);
      background-color: var(--tertiary-color);
    }
  }
}

.logo {
  a {
    font-size: 30px;
    @include respond(phone) {
      font-size: 1.5rem;
    }
    color: white;
  }
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: 2rem;

  li {
    display: block;
  }

  @include respond(phone) {
    // display: none;

    width: 100%;
    height: 100vh;
    background-color: var(--second-color) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: -100%;
    top: 0;
    margin: 0;
    position: fixed;
    transition: all 200ms;

    a {
      opacity: 0;
      transition: opacity 1000ms;
      color: var(--tertiary-color) !important;
    }
    &.expanded {
      right: 0px;

      a {
        opacity: 1;
      }
    }
  }
}
