.filter {
  border: 2px solid var(--second-color);
  padding: 0.5rem;
  font-family: "nulshock", sans-serif;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--second-color);
  background-color: var(--tertiary-color);
  font-weight: 900;

  &Dropdown {
    border-radius: 0.5rem;
    border-top-right-radius: 0;
    position: absolute;
    background-color: var(--tertiary-color);
    z-index: 999;
    border: 2px solid var(--second-color);
    top: 100%;
    min-width: 150%;
    margin: auto;
    right: -2px;
    list-style: none;
    padding: 0;
    overflow: hidden;
    color: var(--second-color);
    li {
      cursor: pointer;

      &:hover {
        background-color: var(--main-color);
      }
      padding: 1rem;
      margin: 0;
      border-bottom: 1px solid var(--second-color);
      &:last-child {
        border-bottom: none;
      }
    }
  }

  &Arrow {
    border: solid var(--second-color);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transition: all 0.3s ease;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-left: 2rem;
  }
}
