@import "../../scss/_mixins.scss";

.imagesSection {
  margin: auto;
  width: 60%;
  position: relative;
  margin-top: 7rem;
  margin-bottom: 10rem;

  @include respond(tab-port) {
    width: 80%;
  }
  @include respond(phone) {
    width: 90%;
  }

  &Image {
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    position: relative;
    border-radius: 0.2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      transform: scale(1.04);
      transition: 500ms ease-in-out;
    }

    &:hover {
      img {
        @media (hover: hover) and (pointer: fine) {
          transform: scale(1.2);
        }
      }

      &Subtitle {
        transform: scale(1.2);
      }
    }

    &Subtitle {
      font-size: 1rem;
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.8rem;
      background-color: var(--tertiary-color);
      text-transform: uppercase;
      cursor: pointer;
      font-weight: 900;
      &:hover {
        background-color: var(--primary-color);
      }

      @include respond(phone) {
        font-size: 0.8rem;
      }
    }

    &Suggestions {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      gap: 1rem;
      opacity: 0;
      transition: all 200ms ease-in-out;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 0.3rem;
      box-sizing: border-box;
      border-radius: 5px;

      @include respond(phone) {
        margin-top: 0px;
      }
    }

    &Ratio {
      top: 0.5rem;
      left: 1rem;
      font-size: 0.9rem;
      text-shadow: 0px 0px 15px rgb(0, 0, 0);
      font-weight: 900;
      padding: 0.5rem;
      opacity: 0;
      transition: all 200ms ease-in-out;
    }

    &:hover {
      &Ratio {
        display: block;
      }

      .imagesSectionImageRatio {
        opacity: 1;
      }

      .imagesSectionImageSuggestions {
        opacity: 1;
      }
    }
  }

  &Filters {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: auto;
    height: auto;
    margin-bottom: 2rem;
    column-gap: 1rem;
    z-index: 9;

    @include respond(phone) {
      justify-content: center;
      flex-wrap: wrap;
      h3 {
        text-align: center;
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
}

.fullScreenImageContainer {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--tertiary-color);

  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagesSectionImageSubtitle {
  bottom: 0;
  top: auto;
}

.fullScreenImage {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  height: 90%;
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .closeButton {
    &:before,
    &:after {
      content: "";
      position: absolute;
      background-color: var(--main-color);
      height: 20px; // Height of the lines forming the 'X'
      width: 4px; // Width of the lines forming the 'X'
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg); // Rotate 45 degrees for the first line
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg); // Rotate -45 degrees for the second line
    }
    position: absolute;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -25px;
    right: -25px;
    background-color: white;
    border-radius: 100%;
    color: rgb(0, 0, 178);
    font-weight: 900;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
    transition: transform 0.15s ease-in-out;
    &:hover {
      cursor: pointer;
      transform: scale(1.3);
    }

    @include respond(phone) {
      left: 0px;
      right: 0px;
      bottom: 2rem;
      top: auto;
      position: fixed;
      height: 30px;
      width: 30px;
      margin: auto;
      padding: 1rem;
      font-size: 2rem;
    }

    cursor: pointer;
  }
}
.sticky {
  position: sticky;
  top: 7.5rem;

  @include respond(tab-port) {
    top: 0rem;
  }

  @include respond(phone) {
    top: 0rem;
  }
}

.sentinel {
  height: 0px;
  width: 100%;
  position: relative;
}

.hidden {
  display: none;
}
