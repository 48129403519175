@import "../../scss/_mixins.scss";

.socialMedia {
  margin: auto;
  width: 60%;
  margin-bottom: 3rem;

  @include respond(phone) {
    width: 95%;
    margin-bottom: 0;
  }

  .iconsContainer {
    display: flex;
    justify-content: space-between;

    @include respond(tab-port) {
      flex-direction: column;
    }

    @include respond(phone) {
      flex-direction: column;
    }
  }

  &Badge {
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @include respond(tab-port) {
      width: 100%;
      margin: auto;
      height: 50px;
      margin-bottom: 1rem;
    }
    @include respond(phone) {
      width: 100%;
      margin: auto;
      height: 20px;
      margin-bottom: 1rem;
    }

    @include respond(big-desktop) {
      height: 50px;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  &MusicServices,
  &Platforms {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 5rem;
    align-items: center;
    row-gap: 1.5rem;

    @include respond(tab-port) {
      flex-direction: column;
    }

    @include respond(phone) {
      flex-direction: column;

      img {
        height: 30px;
      }
    }
    a {
      margin: 0rem;
    }
  }

  &MusicServices {
    margin-bottom: 0;

    @include respond(tab-port) {
      margin-bottom: 5rem;
    }
    @include respond(phone) {
      margin-bottom: 5rem;
    }
  }

  &Platforms {
    column-gap: 1rem;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: nowrap;

    @include respond(tab-port) {
      justify-content: center;
    }

    @include respond(phone) {
      justify-content: center;
    }
    img {
      height: 20px;

      @include respond(tab-port) {
        height: 40px;
      }

      @include respond(phone) {
        height: 20px;
      }
      @include respond(big-desktop) {
        height: 30px;
      }
    }
  }
}
