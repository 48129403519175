@import "../../scss/_mixins.scss";
.hero {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  flex-direction: column;
  background-position: center -200px;
  width: 100%;
  height: 70vh;
  margin-top: 7rem;
  position: relative;

  h1 {
    text-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
  }

  @include respond(big-desktop) {
    background-position: center -500px;
  }
  @include respond(tab-port) {
    background-position: center center;
  }

  @include respond(phone) {
    height: 70vh;

    margin-top: 2rem;
    background-position: center top;
    h1 {
      font-size: 3rem;
      text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    }
  }
}

.saveButton {
  padding: 0.4rem;
  margin-top: 0.5rem;
  border: 2px solid white;
  font-family: Eurostile, sans-serif;
  font-weight: 600;
  font-size: 1.3rem;
  position: absolute;
  bottom: 20%;
  text-transform: uppercase;

  @include respond(big-desktop) {
    bottom: 25%;
  }

  @include respond(phone) {
    bottom: 30%;
  }
}
