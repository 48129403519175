@import "../../scss/_mixins.scss";

img {
  width: 100%;
}

.home {
  display: flex;
  flex-direction: column;
  min-height: 100svh;
}

.contentWrapper {
}
