@import "../../scss/_mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");

.header {
  &First,
  &Second,
  &Third,
  &Fourth,
  &Fifth,
  &Sixth {
    &.black {
    }
    &.white {
    }
    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
    &.center {
      text-align: center;
    }
    &.justify {
      text-align: justify;
    }

    &.isCenterPhone {
      @include respond(tab-port) {
        text-align: center;
      }
      @include respond(phone) {
        text-align: center;
      }
    }
  }

  &First {
    font-size: 5rem;
    margin-bottom: 1rem;

    @include respond(tab-port) {
      font-size: 4rem;
    }

    @include respond(phone) {
      font-size: 2.5rem;
    }

    @include respond(big-desktop) {
      font-size: 8rem;
    }
  }

  &Second {
    font-size: 2rem;

    @include respond(tab-port) {
      font-size: 4rem;
    }

    @include respond(phone) {
      font-size: 2rem;
    }
    @include respond(big-desktop) {
      font-size: 2.5rem;
    }

    overflow-wrap: break-word;
    hyphens: manual;
  }

  &Third {
    font-family: "Inter", sans-serif;
    font-weight: 900;
    font-size: 3rem;

    @include respond(phone) {
      font-size: 2rem;
    }
    @include respond(big-desktop) {
      font-size: 5rem;
    }
  }

  &Fourth {
    font-size: 1.2rem;
  }

  &Fifth {
    color: orange;
  }

  &Sixth {
    font-family: "Eurostile", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    margin-bottom: 1rem;
    text-align: left;
  }
}
