@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import "../../scss/_mixins.scss";

.wrapper {
  @include respond(tab-port) {
    margin-top: 10rem;
  }
  @include respond(phone) {
    margin-top: 6rem;
  }
}

.pressRelease {
  width: 60%;
  padding: 4rem;
  box-sizing: border-box;
  color: #000;
  margin: auto;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  padding-top: 8rem;

  &Video {
    position: relative;
    padding-bottom: 55%; /* 16:9 Aspect Ratio */
    height: 0;
    overflow: hidden;
    margin: auto;
    width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: auto;
    }
  }

  &Spotify {
    width: 80%;
    margin: auto;
    margin: 3rem auto;
  }

  &Bar {
    position: absolute;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    left: 0;
    right: 0;
    top: 0px;
    width: 100%;
    background-color: #efefef;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #dadada;

    &Search {
      height: 20px;
      width: 60%;
      background-color: #fff;
      border-radius: 0.5rem;
      padding: 0.5rem;
      margin: auto;
      font-weight: 400;
      font-family: "Inter", sans-serif;
      color: #aaa;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #b8b9ba;
      img {
        width: 10px;
        height: auto;
        margin-right: 0.2rem;
        opacity: 0.6;
      }
    }

    @include respond(tab-port) {
      display: none;
    }
    @include respond(phone) {
      display: none;
    }
  }

  @include respond(tab-port) {
    width: 80%;
    padding: 2.5rem;
  }

  @include respond(phone) {
    width: 95%;
    padding: 1.5rem;
  }

  &Title {
    font-size: 4rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    @include respond(phone) {
      padding-bottom: 1rem;
      font-size: 1.5rem;
    }
  }
  &Img {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    border-radius: 0.3rem;
    overflow: hidden;

    img {
      margin: 0 auto;
    }
    p {
      background-color: #ececec;
      color: #6b6b6b !important;
      margin: 0;
      padding: 0.5rem;
      box-sizing: border-box;
      margin: auto;
    }
  }

  /* Existing full-width image style */
  &Img {
    width: 100%;
    /* other styles */
  }

  /* New half-width image style */
  &ImgSmall {
    width: 50%;

    @include respond(phone) {
      width: 100%;
    }
    /* other styles, if needed */
  }

  &Author {
    color: var(--main-color);
  }

  &Date {
    margin-top: 0.5rem;
    color: #6b6b6b;
  }

  &BackEffect {
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #7f7f7f;
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 5px;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      z-index: -9;
      background-color: #bbb;
      position: absolute;
      left: -5px;
      top: -5px;
      border-radius: 5px;
    }

    @include respond(phone) {
      display: none;
    }
  }
  p,
  h3 {
    color: #000000;
  }

  i {
    color: #6b6b6b;
  }
}

.spotifyEmbed {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  @include respond(phone) {
    width: 100%;
  }
  &Link {
    font-weight: 900 !important;
    text-decoration: none;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: sans-serif;
    color: #8f8f8f !important;
  }

  &Copy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    border-radius: 0.75rem;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);

    background-color: #efefef;
    margin-bottom: 1rem;

    &Button {
      padding: 0.8rem;
      margin: 0.4rem;
      background-color: var(--main-color);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 0.75rem;
      position: relative;
      cursor: pointer;
      box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.5);

      svg {
        transition: transform 0.3s;
      }

      &:hover {
        svg {
          transform: scale(1.3);
          @include respond(phone) {
            transform: scale(1);
          }
        }
      }
    }
  }

  &Container {
    width: 80%;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 1rem;
    min-height: 152px;
    color: #ccc;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 1rem;
    display: flex;
    font-weight: 900;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5rem 0;
    @include respond(phone) {
      width: 100%;
      text-align: center;
    }

    p {
      margin: 0 1rem;
    }
  }
}

.copiedText {
  position: absolute;
  top: -100%;
  background-color: var(--main-color);
  color: white !important;
  padding: 0.5rem;
  text-wrap: nowrap;
  border-radius: 0.3rem;
  transition: all 0.3s;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: var(--main-color);
    border-width: 6px;
    margin-left: -6px;
  }
}

.opacityFadeout {
  opacity: 0;
  transition: all 0.1s;
}
