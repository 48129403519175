@import "../../scss/_mixins.scss";

.modal {
  color: black;
  z-index: 999999;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);

  iframe {
    width: 80%;
    height: 80vh;
    border: none;

    @include respond(phone) {
      width: 100%;
      height: 80vh;
    }
  }

  &Cross {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-weight: 300;
    color: #fff;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    img {
      width: 50px;
    }
  }
}

.videosContainer {
  width: 80%;
  color: white;
  margin: auto;
  margin-bottom: 10rem;
  margin-top: 10rem;

  &Title {
    text-align: center;
    margin-bottom: 6rem;

    font-size: 5rem;

    @include respond(phone) {
      font-size: 2rem;
    }
  }

  &Videos {
    margin: auto;

    &Video {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 60%;
      height: auto;
      margin: auto;
      margin-bottom: 10rem;
      overflow: hidden;
      position: relative;
      background-color: transparent;
      border: none;
      color: #ffffff;

      @include respond(phone) {
        width: 90%;
        margin-bottom: 5rem;
      }

      &Title {
        width: 100%;
        font-size: 1.3rem;
        text-align: center;
        margin: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: #fff;
      }

      &Image {
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }
      img {
        z-index: 0;
        transition: 3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        width: 100%;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }

      iframe {
        width: 100%;
      }

      &:hover img {
        transform: scale(1.2);
      }
    }
  }

  @include respond(phone) {
    width: 100%;
  }
}

.playIcon {
  position: absolute;
  width: 50px;
  height: 50px;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;
  z-index: 2;
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  overflow: hidden;
  background-color: none;

  @include respond(phone) {
    padding: 0;
  }

  svg {
    width: 100%;
    height: 100%;

    @include respond(phone) {
      width: 60%;
    }
  }

  img {
    width: 1000px;
    position: absolute;
    z-index: -1;
    filter: blur(24px);
    opacity: 0.9;
  }

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    transform: scale(1.1);
  }
}
