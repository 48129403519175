// Paragraph.module.scss

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import "../../scss/_mixins.scss";
.paragraphZero,
.paragraphFirst,
.paragraphSecond,
.paragraphThird {
  font-weight: normal;
  font-family: "Eurostile", sans-serif;

  &.isCenterPhone {
    @include respond(tab-port) {
      text-align: center;
    }
    @include respond(phone) {
      text-align: center;
    }
  }

  &.black {
  }

  &.white {
  }

  &.italic {
    font-style: italic;
  }

  &.bold {
    font-weight: 900;
  }
}

.paragraphZero {
  font-size: 1rem;

  @include respond(tab-port) {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  @include respond(phone) {
    font-size: 1rem;
    line-height: 1.8rem;
  }
}

.paragraphFirst {
  font-size: 1.2rem;
  line-height: 2rem;

  @include respond(tab-port) {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  @include respond(phone) {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  @include respond(big-desktop) {
    font-size: 1.8rem;
    line-height: 3rem;
  }
}

.paragraphSecond {
  font-family: "Inter", sans-serif;
  font-size: 1.12rem;

  @include respond(big-desktop) {
    font-size: 2rem;
    line-height: 3rem;
  }
}

.paragraphThird {
  line-height: 2.8rem;
  font-size: 2.3rem;
  margin-bottom: 4rem;

  @include respond(tab-port) {
    line-height: 2rem;
    font-size: 1.8rem;
  }
  @include respond(phone) {
    line-height: 2rem;
    font-size: 1.5rem;
  }

  @include respond(big-desktop) {
    font-size: 5rem;
    line-height: 5.5rem;
  }
}
