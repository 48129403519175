@import "../../scss/_mixins.scss";

.music {
  width: 375px;
  margin: auto;
  margin-bottom: 5rem;
  text-align: center;
  overflow: hidden;
  display: grid;
  row-gap: 1rem;
  box-shadow: 0 15px 30px #444;
  border-radius: 15px;
  margin-top: 10rem;

  .cover {
    color: white;
    border-radius: 15px;
    overflow: hidden;
    width: 70%;
    margin: auto;
    position: relative;
    margin-top: 3rem;

    .vhs {
      position: absolute;
      top: 0;
      z-index: 999;
      height: 100%;
      mix-blend-mode: soft-light;
      opacity: 0.4;
    }
  }

  .header {
    color: white;
    text-align: center;
    margin: 2rem 0;

    .artistNames {
      margin: 0;
      padding: 0;
    }

    .albumTitle {
      font-size: 1.5rem;
    }

    .subtitle {
      color: rgb(190, 190, 190);
      font-size: 1.3rem;
      font-weight: 500;
      margin: 3rem 0 1rem;
    }
  }

  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }

  .scrollIndicator {
    padding: 0.5rem;
    margin: auto;
    margin-bottom: -40px;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    position: relative;
    height: 30px;

    .arrow {
      color: black;
      border-radius: 100%;
      background-color: white;
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.65);
      position: absolute;
      z-index: 888888;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 40%;
      }
    }

    .pulse {
      background-color: white;
      animation: pulsate 2.5s ease-out infinite;
      background-color: white;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      position: absolute;
    }
  }

  .platforms {
    color: #000;
    background-color: white;
    display: grid;
    flex-direction: column;
    row-gap: 3rem;
    padding-top: 2rem;
    padding: 40px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .platform {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: auto;

      img {
        width: 40%;
      }

      a {
        span {
          font-family: sans-serif;
          color: #666;
          display: block;
          text-align: center;
          margin: auto;
          margin-left: auto;
          border: 1px solid rgb(201, 201, 222);
          box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
          margin: 0;
          padding: 10px 20px;
          font-size: 1rem;
          font-weight: 400;
          border-radius: 100px;
          transition: all 0.2s ease-in-out;

          &:hover {
            background-color: black;
            color: white;
          }
        }
      }
    }
  }
}
