@import "../../scss/_mixins.scss";
.temp {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 100%;
  position: relative;

  @include respond(phone) {
    width: 70%;
  }
}
.recordSpinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &Img {
    z-index: 999;
    width: calc(100%);
    height: calc(100%);
  }

  &Cover {
    position: absolute;
    height: 100%;
    width: auto;
    z-index: 999;

    &Container {
      position: absolute;
      height: calc(100% - 5px);
      width: calc(100% - 5px);
      overflow: hidden;
      z-index: 888;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;

      h1 {
        position: absolute;
        z-index: 999;
        font-size: 2.5rem;
        color: #fff;
        text-align: center;
        // top: 3rem;

        @include respond(big-desktop) {
          font-size: 4rem;
        }

        @include respond(phone) {
          font-size: 2rem;
        }
      }
    }
  }

  &Holo {
    background: radial-gradient(
        circle at center,
        #e6e6e6 0%,
        #c0c0c0 40%,
        #a8a8a8 80%,
        #909090 100%
      ),
      linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25.1%,
        transparent 100%
      );
    box-shadow: inset 0 2px 2px rgba(255, 255, 255, 0.1),
      0 2px 4px rgba(0, 0, 0, 0.2);

    background-blend-mode: overlay;
    background-size: 300% 300%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 95%;
      height: 95%;
      background-image: linear-gradient(
          0deg,
          transparent 0%,
          rgba(255, 255, 255, 0.8) 50%,
          transparent 100%
        ),
        linear-gradient(
          90deg,
          transparent 0%,
          rgba(255, 255, 255, 0.8) 50%,
          transparent 100%
        );
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &Spindle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3rem; /* Adjust size as needed */
    height: 3rem; /* Adjust size as needed */
    @include respond(big-desktop) {
      width: 5.2rem; /* Adjust size as needed */
      height: 5.2rem; /* Adjust size as needed */
    }
    background-color: #000; /* Spindle color */
    border: 3px solid
        radial-gradient(
          circle at center,
          #e6e6e6 0%,
          #c0c0c0 40%,
          #a8a8a8 80%,
          #909090 100%
        ),
      linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25.1%,
        transparent 100%
      ); /* Spindle edge color */
    border-radius: 50%;
    transform: translate(-50%, -50%);
    box-shadow: inset 0 0 5px #333; /* Gives depth to the spindle */
    z-index: 999999;

    @include respond(phone) {
      width: 37px;
      height: 37px;
    }
  }
}
