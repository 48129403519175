@import "../../scss/_mixins.scss";

.neoButton {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  bottom: 20px;
  right: 20px;
  background: none;
  cursor: pointer;
  background-color: #ffffff;
  border: none;

  @include respond(phone) {
    bottom: 0;
    right: 40px;
  }

  // Default state is the pause icon (two vertical bars)
  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: var(--main-color);

    // transition: all 0.3s ease-in-out;
    top: 50%;
    height: 15px; // Height of the pause bars
    width: 4px; // Width of the pause bars
    transform: translateY(-50%);
  }

  &:before {
    left: calc(50% - 6px); // Position the left bar
  }

  &:after {
    left: calc(50% + 2px); // Position the right bar
  }

  // Play icon (when toggled)
  &.play {
    &:before {
      // Transform the left bar into the triangle shape pointing to the right
      background-color: transparent;
      border-left: none;
      border-top: 9px solid transparent; // size of the triangle
      border-bottom: 9px solid transparent; // size of the triangle
      border-right: 14px solid var(--main-color); // size of the triangle

      // Center the triangle in the button
      top: 50%;
      left: 55%;
      // Use translate to shift the triangle back by half of its width and height
      transform: translate(-50%, -50%) rotate(180deg); // rotate to point to the right
      width: 0; // width must be 0 for the triangle to work
      height: 0; // height must be 0 for the triangle to work
    }

    &:after {
      // Hide the right bar since it's not needed for play icon
      content: none;
    }
  }
}
